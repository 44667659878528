<template>
  <MDropdown ref="dropdownMenu" @show="handleActionMenuShow">
    <template v-slot:trigger>
      <MButton
        id="action-btn"
        variant="neutral-lighter"
        class="ml-1"
        :shadow="false"
        shape="circle"
      >
        <MIcon name="ellipsis-v" size="2x" />
      </MButton>
    </template>
    <template v-slot:menu>
      <MMenu class="action-menu">
        <MMenuItem
          v-if="!disabled && allowAskForApproval"
          id="ask-for-approval"
        >
          <ApprovalAction
            :module-name="moduleName"
            :parent-id="ticket.id"
            :resource-name="ticket.name"
            :service-catalog-id="ticket.serviceCatalogId"
            @trigger="hide"
            @done="$emit('approval-changed')"
          />
        </MMenuItem>
        <MMenuItem
          v-if="!disabled && moduleName === $constants.REQUEST"
          id="split-request"
        >
          <SplitRequestAction
            :module-name="moduleName"
            :resource="ticket"
            @trigger="hide"
          />
        </MMenuItem>
        <MMenuItem
          v-if="!ticket.spam && !disabled && moduleName === $constants.REQUEST"
          id="mark-as-spam"
        >
          <MarkAsSpam
            :module-name="moduleName"
            bind-shortcut
            @trigger="hide"
            @done="$emit('update', { spam: true })"
          />
        </MMenuItem>
        <MMenuItem
          v-if="!disabled && moduleName === $constants.REQUEST"
          id="add-watcher"
        >
          <AddWatcher
            :resource-id="ticket.id"
            :module-name="moduleName"
            @trigger="hide"
          />
        </MMenuItem>
        <MMenuItem
          v-if="!disabled && moduleName === $constants.REQUEST"
          id="watch"
        >
          <Watch
            :is-watching="isUserWatching"
            bind-shortcut
            @watch="
              handleUpdateWatcher({
                ...watcher,
                technicians: [...watcher.technicians, user.id],
              })
            "
            @unwatch="
              handleUpdateWatcher({
                ...watcher,
                technicians: watcher.technicians.filter((e) => e !== user.id),
              })
            "
            @done="hide"
          />
        </MMenuItem>
        <MMenuItem
          v-if="
            allowSendFeedbackManually &&
            !disabled &&
            moduleName === $constants.REQUEST
          "
          id="ask-for-feedback"
        >
          <SendFeedback :resource-id="ticket.id" @trigger="hide" />
        </MMenuItem>
        <MMenuItem
          v-if="!disabled && moduleName === $constants.REQUEST"
          id="scenario"
        >
          <Scenario
            :title="`${ticket.name}: ${ticket.subject}`"
            :module-name="moduleName"
            bind-shortcut
            :resource-id="ticket.id"
            @trigger="hide"
          />
        </MMenuItem>
        <MMenuItem
          v-if="moduleName === $constants.REQUEST"
          id="status-transition"
        >
          <TransitionDrawer
            :resource="ticket"
            :module-name="moduleName"
            type="status"
            @trigger="hide"
          >
            <template v-slot:trigger="{ open }">
              <div @click="open">
                <MIcon name="exchange" class="action-menu-icon" />
                <span class="ml-1 action-menu-text">
                  {{ $t('status') }} {{ $t('transition') }}
                </span>
              </div>
            </template>
          </TransitionDrawer>
        </MMenuItem>
        <MMenuItem
          v-if="moduleName === $constants.REQUEST"
          id="assignment-transition"
        >
          <TransitionDrawer
            :resource="ticket"
            :module-name="moduleName"
            type="assignment"
            @trigger="hide"
          >
            <template v-slot:trigger="{ open }">
              <div @click="open">
                <MIcon name="exchange" class="action-menu-icon" />
                <span class="ml-1 action-menu-text">
                  {{ $t('assignment') }} {{ $t('transition') }}
                </span>
              </div>
            </template>
          </TransitionDrawer>
        </MMenuItem>
        <MMenuItem
          v-if="
            !isTicketClosed &&
            !isTicketResolve &&
            moduleName === $constants.REQUEST &&
            hasRequestModule &&
            hasServiceCatalogModule
          "
          id="converion-action"
        >
          <ConversionDrawer
            :module-name="moduleName"
            :resource="ticket"
            @trigger="hide"
            @refresh="$emit('refresh')"
          />
        </MMenuItem>
        <MMenuItem id="archive">
          <Archive
            :resource-id="ticket.id"
            :module-name="moduleName"
            bind-shortcut
            @trigger="hide"
            @done="handleArchive"
          />
        </MMenuItem>
        <MMenuItem id="print">
          <Print
            :resource-id="ticket.id"
            :resource="ticket"
            :service-catalog-id="ticket.serviceCatalogId"
            :module-name="moduleName"
            @trigger="hide"
          />
        </MMenuItem>
      </MMenu>
    </template>
  </MDropdown>
</template>

<script>
import { authComputed } from '@state/modules/auth'
import { LicenseComputed } from '@state/modules/license'
import api from '@api'
import Bus from '@utils/emitter'
import ApprovalAction from '@components/approval/approval-action'
import MarkAsSpam from './action-menu-items/mark-as-spam'
import AddWatcher from './action-menu-items/add-watcher'
import Watch from './action-menu-items/watch'
import SendFeedback from './action-menu-items/send-feedback'
import Scenario from './action-menu-items/scenario'
import Print from './action-menu-items/print'
import Archive from './action-menu-items/archive'
import SplitRequestAction from './action-menu-items/split-request-action'
import TransitionDrawer from './action-menu-items/transition-drawer/transition-drawer'
import ConversionDrawer from './action-menu-items/conversion/conversion'
import { getWatcherApi, updateWatcherApi } from '../ticket-api'

export default {
  name: 'ActionMenu',
  components: {
    MarkAsSpam,
    AddWatcher,
    Watch,
    SendFeedback,
    Scenario,
    Print,
    ApprovalAction,
    Archive,
    SplitRequestAction,
    TransitionDrawer,
    ConversionDrawer,
  },
  props: {
    moduleName: { type: String, required: true },
    changeStage: { type: String, default: undefined },
    ticket: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    isTicketClosed: { type: Boolean, default: false },
    isTicketResolve: { type: Boolean, default: false },
    updateWatcher: { type: Function, required: true },
  },
  data() {
    return {
      allowSendFeedbackManually: true,
      watcher: [],
    }
  },
  computed: {
    ...authComputed,
    ...LicenseComputed,
    hasRequestModule() {
      return (
        this.availableModulesInLicense.indexOf(this.$constants.REQUEST) >= 0
      )
    },
    hasServiceCatalogModule() {
      return (
        this.availableModulesInLicense.indexOf(
          this.$constants.SERVICE_CATALOG
        ) >= 0
      )
    },
    isUserWatching() {
      if (
        this.watcher &&
        (this.watcher.technicians || []).indexOf(this.user.id) >= 0
      ) {
        return true
      }
      return false
    },
    allowAskForApproval() {
      if (
        this.moduleName === this.$constants.CHANGE ||
        this.moduleName === this.$constants.RELEASE
      ) {
        return this.changeStage === 'approval' && !this.disabled
      }
      return !this.ticket.spam && !this.disabled
    },
  },
  created() {
    if (this.moduleName === this.$constants.REQUEST) {
      api.get('/feedbacksetting').then((data) => {
        this.allowSendFeedbackManually = data.sendManually
      })
    }
    const handler = (e, k, command, nextCommand) => {
      if (
        ['scenario', 'mark-spam', 'watch'].indexOf(nextCommand) >= 0 &&
        this.moduleName !== this.$constants.REQUEST
      ) {
        return
      }
      if (nextCommand === 'watch' && this.isUserWatching) {
        return
      }
      this.$refs.dropdownMenu.show()
    }
    Bus.$on('dropdown-open', handler)
    this.$off('hook:beforeDestroy', () => {
      Bus.$off('dropdown-open', handler)
    })
  },
  methods: {
    getWatcher() {
      return getWatcherApi(this.moduleName, this.ticket.id).then((data) => {
        this.watcher = data
        return data
      })
    },
    handleUpdateWatcher(data) {
      return updateWatcherApi(this.moduleName, {
        ...data,
        id: this.ticket.id,
      })
    },
    hide() {
      this.$refs.dropdownMenu.hide()
    },
    handleArchive() {
      this.$emit('archive')
    },
    handleActionMenuShow() {
      this.getWatcher()
    },
  },
}
</script>
